export const ISO_DATE_FORMAT = "YYYY-MM-DD"
export const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATE_FORMAT = "MM/DD/YYYY"
export const DEFAULT_DATE = "0001-01-01"
export const DRAFT_POSTFIX = "(draft)"
export const NUMBER_VALID_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
export const DOT = "."
export const DASH = "-"
export const NAVIGATION_KEYS = ["ArrowDown", "ArrowLeft", "ArrowRight", "ArrowUp", "End", "Home", "PageDown", "PageUp"]
export const EDITING_KEYS = ["Backspace", "Clear", "Copy", "CrSel", "Cut", "Delete", "EraseEof", "ExSel", "ExSel", "Paste", "Redo", "Undo"]
export const TRUE_ARRAY = ["1", "TRUE", "true", true];
export const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
export const PHONE_10DIGITS_FORMAT = "000-000-0000"
export const PHONE_REGEX: RegExp = /(\d{3})(\d{3})(\d{4})/
export const HYPHEN_REGEX: RegExp = /(\d{2})(\d{2})/
export const PHONE_REGEX_DASH: RegExp = /\d{3}-\d{3}-\d{4}/
export const DEFAULT_CURRENCY = "USD"
export const DEFAULT_CURRENCY_FORMAT = "$"
export const DEFAULT_LOCALE = 'en-US'
export const valueDefaultSourceField = 'custom_data_layout_field_1384A6FE-F3AD-4C18-9237-0F9BB1FC1860';
export const PHONE_9DIGITS_FORMAT = "000-000-000"
export const PHONE_9DIGITS_REGEX: RegExp = /(\d{3})(\d{3})(\d{3})/
export const PHONE_9DIGITS_REGEX_DASH: RegExp = /\d{3}-\d{3}-\d{3}/


// label
export const CONFIRM_LABEL = 'Confirm'
export const VERIFY_LABEL = 'Verify'
export const STATE_PROFILE_LABEL = "State Profile"
export const STATE_PROVINCE_LABEL = "State/Province"
export const STATE_PROVINCE_SPACE_LABEL = "State / Province"
export const STATE_REGION_LABEL = "State/Region"
export const PROFILE_NAME_LABEL = "Profile Name"
export const PROFILE_LABEL = "Profile"
export const RETAIL_TYPE_LABEL = "Retail Type"
export const GENRE_LABEL = "Genre"
export const COUNTRY_LABEL = "Country"
export const FEIN_LABEL = "FEIN (Federal Employer ID Number)"
export const SIC_LABEL = "SIC (Standard Industry Code)"
export const ALIAS_LABEL = "Alias"
export const ENTITY_CODE_LABEL = "Entity Code"
export const ENTITY_NAME_LABEL = "Entity Name"
export const NEW_PROFILE_TITLE = "New Profile"
export const COPY_AS_NEW_PROFILE_TITLE = "Copy As New"
export const ADD_CLIENT_TITLE = "Add Client"
export const EDIT_CLIENT_TITLE = "Edit Client"
export const CLIENT_CODE_LABEL = "Client Code"
export const CLIENT_NAME_LABEL = "Client Name"
export const DESCRIPTION_LABEL = "Description"
export const CURRENCY_CODES_LABEL = "Currency Codes"
export const CURRENCY_LABEL = "Currency"
export const ADDRESS_LABEL = "Address"
export const ADDRESS_2_LABEL = "Address 2"
export const ADDRESS_3_LABEL = "Address 3"
export const CITY_LABEL = "City"
export const STATE_LABEL = "State"
export const ZIP_LABEL = "Zip/Postal Code"
export const FIRST_NAME_LABEL = "First Name"
export const LAST_NAME_LABEL = "Last Name"
export const PHONE_LABEL = "Phone"
export const EMAIL_LABEL = "Email Address"
export const COMPANY_TYPE_LABEL = "Company Type"
export const FAX_LABEL = "Fax"
export const TITLE_LABEL = "Title"
export const NAME_LABEL = "Name"
export const FILING_TAX_ID_EXT_LABEL = "Filing Tax Id Ext"
export const FILING_TAX_ID_LABEL = "Filing Tax Id"
export const ISSUER_TYPE_LABEL = "Issuer Type"
export const PROGRAM_LABEL = "Program"
export const CLIENT_LABEL = "Client"
export const ISSUER_LABEL = "Issuer"
export const ISSUER_SETUP_LABEL = "Issuer Setup"
export const PROCESSOR_LABEL = "Processor"
export const RETAILER_LABEL = "Retailer"
export const NOTICES_LABEL = "Notices"
export const FILINGS_LABEL = "Filings"
export const DOES_CARD_COMPLIANT_DELIVER_NOTICES_LABEL = "Does Card Compliant Deliver Notices"
export const DOES_CARD_COMPLIANT_RECEIVE_RESPONSES_LABEL = "Does Card Compliant Receive Responses"
export const INHERITED_FROM_CLIENT_TOOLTIP = "Inherited from the client"
export const SAVE_LABEL = "Save"
export const OK_LABEL = "OK"
export const SAVE_DRAFT_LABEL = "Save Draft"
export const CREATE_DRAFT_LABEL = "Create Draft"
export const CANCEL_LABEL = "Cancel"
export const DELETE_LABEL = "Delete"
export const PUBLISH_LABEL = "Publish"
export const SAVE_AND_PUBLISH_LABEL = "Save & Publish"
export const CREATED_DATE_LABEL = "Created Date"
export const ACTION_LABEL = "Action"
export const CREATE_AS_NEW_LABEL = "Create New Profile"
export const EDIT_ORGANIZATION_ENTITY_LABEL = "Edit Organization Entity"
export const GENERAL_LABEL = "General"
export const GENERAL_INFO_LABEL = "General Info"
export const BILLING_LABEL = "Billing"
export const OPTI_EARNINGS_LABEL = "Earnings Recognition (OptiEarnings)"
export const ISSUING_LABEL = "Issuing"
export const ETL_LABEL = "ETL"
export const RISKS_PREFERENCES_LABEL = "Risks & Preferences"
export const PREFERENCES_LABEL = "Preferences"
export const ORGANIZATION_LABEL = "Organization"
export const NOTICE_INFORMATION_LABEL = "Notices"
export const BILLING_CONTACT_LABEL = "Billing Contact"
export const TECHNICAL_CONTACT_LABEL = "Technical Contact"
export const FIRST_CONTACT_LABEL = "1st Contact (Primary)"
export const SECONDARY_CONTACT_LABEL = "2nd Contact (Secondary)"
export const BUSINESS_ADDRESS_LABEL = "Business Address"
export const LEAD_ISSUER_LABEL = "Lead Issuer"
export const OTHER_ISSUER_LABEL = "Other Issuer"
export const LEAD_ISSUER_TYPE_LABEL = "Lead Issuer Type"
export const OTHER_ISSUER_TYPE_LABEL = "Other Issuer Type"
export const CLIENT_NUMBER_LABEL = "Client Number"
export const DATA_LAYOUT_LABEL = "Data Layout"
export const DUPLICATE_RECORDS_LABEL = "Duplicate Records"
export const ACCOUNT_ID_LABEL = "Account UUID"
export const ESCHEATABLE_DUE_DATE_LABEL = "Escheatable Due Date"
export const VERIFY_ACCOUNT_LABEL = "Verify Account"
export const SIMULATE_LABEL = "Simulate"
export const RUN_LABEL = "Run"
export const DATA_STREAM_NAME_LABEL = "Data Stream Name"
export const DATA_LAYOUT_NAME_LABEL = "Data Layout Name"
export const CREATE_NEW_DATA_STREAM_LABEL = "Create New Data Stream"
export const CREATE_NEW_DATA_LAYOUT_LABEL = "Create New Data Layout"
export const NEW_DATA_TYPE_FIELD_MAPPING_LABEL = "New Data Type Field Mapping"
export const NEW_DATA_FIELD_MAPPING_LABEL = "New Data Field Mapping"
export const TAX_INFO_LABEL = "Tax Info"
export const FISCAL_CALENDAR_LABEL = "Fiscal Calendar"
export const CONTACTS_LABEL = "Contacts"
export const ADDRESSES_LABEL = "Addresses"
export const OPTIFOR_LABEL = "Breakage Forecasting (OptiFor)"
export const OPTIFILER_LABEL = "Notices and Filing (OptiFiler)"
export const OPTIMAX_LABEL = "Escheat (OptiMax)"
export const FILING_LABEL = "Filing"
export const REPORTING_LABEL = "Reporting"
export const PORTFOLIO_LABEL = "Portfolio"
export const CUSTOM_REPORTING_PERIOD_LABEL = "Custom Reporting Periods"
export const PROGRAMS_LABEL = "Programs"
export const CREATE_PROGRAM_LABEL = "Create New Program"
export const STATUS_LABEL = "Status"
export const FACTOR_SETTING_LABEL = "Factor Settings"
export const FORECASTING_PARAMETER_LABEL = "Forecasting Parameters"
export const DE_RECOGNITION_INFORMATION_LABEL = "Derecognition Information"
export const FEE_PROGRAM_LABEL = "Fee Program"
export const CLIENT_INFO_LABEL = "Client Info"
export const SERVICES_LABEL = "Services"
export const ESCHEATMENT_LABEL = "Escheatment"
export const NOTICES_AND_FILLINGS_LABEL = "Notices and Filings"
export const REIMBURSEMENTS_LABEL = "Reimbursements"
export const REVENUE_RECOGNITION_LABEL = "Revenue Recognition"
export const FORECASTING_LABEL = "Forecasting"
export const BUSINESS_LABEL = "Business"
export const FIN_CEN_REPORTS_LABEL = "FinCen Reports"



export const PROPAGATE_CHANGES_EXISTING_RELATED_PROGRAM_SUBTITLE = "On Approval, these changes will become defaults for newly created Programs related to this Issuer. Should these changes also be applied, on Approval, to all existing Programs related to this Issuer?"
export const PROPAGATE_CHANGES_EXISTING_RELATED_PROGRAM_CHECKBOX = "Apply Issuer Changes to All Related Programs on Approval";
export const REIMBURSEMENTS_TITLE = "Reimbursements";
export const REIMBURSEMENTS_IN_PROGRESS_LABEL = "A Reimbursement for this Client and State is already in progress.";
export const REIMBURSEMENTS_NOTIFY_LABEL = "You will be notified when it is finished processing.";
export const EXISTING_CONTACT_FOUND_TITLE = "Existing Contact Found";
export const EXISTING_CONTACT_FOUND_SUBTITLE = "A contact with this email address already exists. Would you like to associate with this entity?";

//Routes
export const ROUTE = {
    DATA_STREAMS: "/data-management/data-streams",
    DATA_LAYOUTS: "/data-management/data-layouts",
    TRANSACTION_CODES: "/data-management/transaction-codes",
    CARD_STATUS_CODES: "/data-management/card-status-codes",
    FILINGS_NOTICES: "/escheatment-activities/filings-notices",
    REIMBURSEMENTS: "/escheatment-activities/reimbursements",
    SIMULATIONS: "/escheatment-activities/simulation",
    NOTIFICATIONS: "/notifications",
    REVIEWS_AND_APPROVALS: "/reviews-and-approvals",
    DATA_TRANSFORMATIONS: "/data-management/data-transformations",
    ORGANIZATIONS: "/data-management/organizations",
}

// Title
export const APPLICATION_TITLE = "Card Compliant"
export const UN_CHANGE_TITLE = "Unsaved Changes"
export const DELETE_DRAFT_TITLE = "Delete Draft"
export const SAVE_AS_NEW_DRAFT_TITLE = "Create Draft"
export const DELETE_SIMULATIONS_TITLE = "Delete Simulation"
export const NEW_DATA_STREAM_TITLE = "New Data Stream"
export const NEW_DATA_LAYOUT_TITLE = "New Data Layout"
export const NEW_SILVER_FIELD_TITLE = "Create New Silver Field"
export const COPY_AS_NEW_SILVER_FIELD_TITLE = "Copy Silver Field As New"
export const UPDATE_SILVER_FIELD_TITLE = "Edit Silver Field"
export const CHECK_PROGRAM_CONFLICT_TITLE = "Related Program Conflict Check"
export const CHECK_PROGRAM_CONFLICT_SUBTITLE = "Changes made to this Issuer differ from related Programs below."
export const PROPAGATE_CHANGES_EXISTING_RELATED_PROGRAM_TITLE = "Propagate Changes to Existing Related Programs?"
export const CHANGE_STATUS_DRAFT_TITLE = "Manage Entity Status"
export const CREATE_PROGRAM_TITLE = "Create New Program"



export const ISSUER_STATUS = {
    New: "New",
    Implementing: "Implementing",
    QA: "QA",
    Active: "Active",
}

export const PAGE_ROUTE_ACTION_TYPE = {
    EditOrigin: "edit",
    EditStaging: "edit-draft",
}
