import { SxProps, Theme } from "@mui/material";
import { DateView } from "@mui/x-date-pickers";
import { currencyFormat } from "@utils/helpers";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { string } from "zod";

type TableStructure = {
    keyColumn: string;
    columns: {
        colName: string;
        colSpan: number;
        propertyName: string;
        hasFilter?: boolean;
        mappingData?: string[];
        colFormat?: typeof currencyFormat,
        cellSx?: any,
        sx?: SxProps<Theme>
    }[];
};

type ListItem = {
    name: string;
    value: string | number | boolean;
    icon?: ReactNode | string;
};

enum ModalAction {
    OPEN = 0,
    CLOSE = 1,
    CLOSE_SUCCESS = 2,
    CLOSE_SUCCESS_2 = 3,
}

enum ActionStatus {
    SUCCESS = "SUCCESS",
    FAIL = "FAIL",
    UNSET = "UNSET",
}

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Inactive = "Inactive",
}

export interface ValidationError {
    message: string[] | undefined;
    isInvalidFormat: boolean;
}

type FormState = {
    key: ActionStatus;
    message: string;
    errors: Record<string, ValidationError[] | undefined>;
    timestamp?: number;
};

const ROLE_ADMIN = "admin";
const SELECT_ALL_VALUE = "all-value";

const EMPTY_FORM_STATE: FormState = {
    key: ActionStatus.UNSET,
    message: "",
    errors: {},
    timestamp: Date.now(),
};

type FieldErrorProps = {
    formState: FormState;
    name: string;
    textAlign?: TextAlign;
    childKey?: string
};

interface IPageCriteria {
    keyWord?: string;
    orderBy?: string;
    desc?: boolean;
    page?: number;
    pageSize?: number;
}

type FormFieldType = "string" | "number" | "list" | "date" | "boolean" | "multiple" | "checkbox" | "radio" | "empty" | "autoComplete" | "multipleSelect" | "typography" | "divider" | "onlyString";

type TextAlign = "left" | "right" | "center";

interface IPropertyType {
    type: FormFieldType;
    name: string;
    text: string | ReactNode;
    defaultValue?: any;
    showSelect?: boolean;
    placeHolder?: string;
    onlyNumber?: boolean;
    isDisabled?: boolean;
    isRequired: boolean;
    isHidden?: boolean;
    onChange: Function;
    onBlur?: Function;
    formState?: FormState;
    decimalDigits?: number;
    listValues?: any[];
    zIndex?: number;
    xsLabel?: number;
    xsInput?: number;
    xsFormControl?: number;
    mdFormControl?: number;
    textAlign?: TextAlign;
    textErrorAlign?: TextAlign;
    itemSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    inputSx?: SxProps<Theme>;
    isHideLabel?: boolean;
    isVertical?: boolean;
    isFreeSolo?: boolean;
    errorName?: string;
    itemFocus?: boolean;
    isExpanded?: boolean;
    allowUndefinedDefaultSelect?: boolean;
    childKey?: string;
    disableClearable?: boolean;
    isLabelToolTip?: boolean;
    isInputToolTip?: boolean;
    labelToolTip?: React.ReactNode,
    isDisableDefaultSelect?: boolean;
    highlightedDays?: any[];
    checkBoxSx?: SxProps<Theme>;
    formatValue?: Function;
    formatDate?: string;
    valueDefaultSelect?: any;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    radioInline?: boolean;
    // datepicker
    shouldDisableMonth?: (month: dayjs.Dayjs) => boolean;
    shouldDisableYear?: (year: dayjs.Dayjs) => boolean;
    shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
    datePickerViews?: DateView[];
    openTo?: DateView;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    isShowSeparateLabel?: boolean;
    separateLabel?: string;
    isHideHeader?: boolean;
    isShowRequiredAsterisk?: boolean;
    hasEndAdornment?: boolean;
    endAdornmentLabel?: string;
    min?: number;
    max?: number;
    thousandSeparator?: boolean;
    allowLeadingZeros?: boolean;
    allowNegative?: boolean;
    fixedDecimalScale?: boolean;
    decimalSeparator?: string;
    isPercentNumber?: boolean;
}

interface IFormControlProperty {
    label: string | ReactNode;
    name: string;
    defaultValue?: any;
    value: any;
    placeHolder?: string;
    onlyNumber?: boolean;
    isRequired: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    onChange: Function;
    formState?: FormState;
    isNumberInput?: boolean;
    decimalDigits?: number;
    xsLabel?: number;
    xsInput?: number;
    xsFormControl?: number;
    mdFormControl?: number;
    errorName?: string;
    textAlign?: TextAlign;
    textErrorAlign?: TextAlign;
    itemSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    inputSx?: SxProps<Theme>;
    isExpanded?: boolean;
    zIndex?: number;
    childKey?: string;
    disableClearable?: boolean;
    isLabelToolTip?: boolean;
    isInputToolTip?: boolean;
    labelToolTip?: ReactNode;
    isDisableDefaultSelect?: boolean;
    highlightedDays?: any[];
    isHideLabel?: boolean;
    itemFocus?: boolean;
    isShowRequiredAsterisk?: boolean;
    formatValue?: Function;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

interface IMenuItem {
    id: string;
    text: string;
    pageName?: string;
    pageDescription?: string;
    name?: string;
    href?: string;
    icon?: ReactNode | string;
    children?: IMenuItem[];
    guardActive?: boolean;
    powerBIReportId?: number;
}

interface ITextFormControlProperty extends IFormControlProperty {
    onBlur?: Function;
    hasEndAdornment?: boolean;
    endAdornmentLabel?: string;
}

interface IDropdownFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: ListItem[] | any[];
    showSelect?: boolean;
    isExpanded?: boolean;
    allowUndefinedDefaultSelect?: boolean;
    isDisableDefaultSelect?: boolean;
    valueDefaultSelect?: any;
}

interface IAutoCompleteFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: ListItem[] | any[];
    showSelect?: boolean;
    isFreeSolo?: boolean;
    disableClearable?: boolean;
}

interface ICheckboxFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    isShowCheckboxLabel?: boolean;
    checkBoxSx?: SxProps<Theme>;
}

interface IRadioFormControlProperty extends IFormControlProperty {
    listValues?: any[];
    isHideLabel?: boolean;
    isVertical?: boolean;
    labelToolTip?: ReactNode,
    isLabelToolTip?: boolean;
    isInputToolTip?: boolean;
    afterLabelComponent?: ReactNode;
    radioInline?: boolean;
}

interface IMultipleSelectFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: any[];
}

interface IDateFormControlProperty extends IFormControlProperty {
    formatDate?: string;
    shouldDisableMonth?: (month: dayjs.Dayjs) => boolean;
    shouldDisableYear?: (year: dayjs.Dayjs) => boolean;
    shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
    datePickerViews?: DateView[];
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    isShowSeparateLabel?: boolean;
    separateLabel?: string;
    isHideHeader?: boolean;
    openTo?: DateView;
}

interface INumberFormControlProperty extends IFormControlProperty {
    endAdornmentLabel?: string;
    min?: number;
    max?: number;
    thousandSeparator?: boolean;
    allowLeadingZeros?: boolean;
    allowNegative?: boolean;
    fixedDecimalScale?: boolean;
    decimalSeparator?: string;
    isPercentNumber?: boolean;
}

const defaultEmptyProperties: IPropertyType = {
    type: "empty",
    name: "empty name",
    text: "empty",
    textAlign: "left",
    isDisabled: false,
    isRequired: false,
    onChange: () => { },
    xsFormControl: 12,
}

const defaultStringProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "string",
}

const defaultNumberProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "number",
    decimalDigits: 0,
    thousandSeparator: true,
    fixedDecimalScale: true,
}

const defaultDropDownProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "list",
    listValues: [],
}


const defaultMultipleSelectProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "multipleSelect",
    listValues: [],
}

const defaultAutoCompleteProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "autoComplete",
    listValues: [],
}

const defaultDateProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "date",
}

const defaultMultiLineProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "multiple",
}

const defaultToggleProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "boolean",
}

const defaultCheckboxProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "checkbox",
}

const defaultRadioProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "radio",
}

const defaultTypographyProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "typography",
}

const defaultDividerProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "divider",
}

const defaultOnlyStringProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "onlyString",
}

const defaultFormControlProperties = {
    xsFormControl: 6,
    mdFormControl: 3,
}

interface IBreadCrumbLink {
    text: string;
    href?: string;
}

export class ApiResult<T> {
    success: boolean = false;
    messages?: string[] = [];
    data?: T

    constructor(initializeData?: any) {
        if (initializeData) {
            Object.assign(this, initializeData);
        }
    }

    get firstMessage(): string | undefined {
        return (this.messages && this.messages.length > 0) ? this.messages[0] : undefined
    };

    static createError<T>(message?: string, data?: T): ApiResult<T> {
        const result = new ApiResult<T>;
        result.success = false;
        result.messages = message ? [message] : [];
        result.data = data;
        return result;
    }

    static createSuccess<T>(message?: string, data?: T): ApiResult<T> {
        const result = new ApiResult<T>;
        result.success = true;
        result.messages = message ? [message] : [];
        result.data = data;
        return result;
    }
}

export const STAGING_PREFIX = "staging_";

const LIST_MONTHS = [
    { name: 'January', value: "1" },
    { name: 'February', value: "2" },
    { name: 'March', value: "3" },
    { name: 'April', value: "4" },
    { name: 'May', value: "5" },
    { name: 'June', value: "6" },
    { name: 'July', value: "7" },
    { name: 'August', value: "8" },
    { name: 'September', value: "9" },
    { name: 'October', value: "10" },
    { name: 'November', value: "11" },
    { name: 'December', value: "12" },
]

export {
    ActionStatus,
    EMPTY_FORM_STATE,
    ROLE_ADMIN,
    SELECT_ALL_VALUE,
    ModalAction,
    defaultStringProperties,
    defaultAutoCompleteProperties,
    defaultNumberProperties,
    defaultDropDownProperties,
    defaultDateProperties,
    defaultMultiLineProperties,
    defaultToggleProperties,
    defaultCheckboxProperties,
    defaultEmptyProperties,
    defaultRadioProperties,
    defaultMultipleSelectProperties,
    defaultFormControlProperties,
    defaultTypographyProperties,
    LIST_MONTHS,
    defaultDividerProperties,
    defaultOnlyStringProperties,
}
export type { TableStructure, ListItem, FormState, FieldErrorProps, FormFieldType, TextAlign, }
export type {
    IPageCriteria,
    IPropertyType,
    IFormControlProperty,
    IDropdownFormControlProperty,
    IAutoCompleteFormControlProperty,
    IMenuItem,
    ICheckboxFormControlProperty,
    IRadioFormControlProperty,
    IBreadCrumbLink,
    IMultipleSelectFormControlProperty,
    ITextFormControlProperty,
    IDateFormControlProperty,
    INumberFormControlProperty,
}

