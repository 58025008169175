import { verticalFormControlGridStyle } from "@styles/global-css-class";
import { FormState, IPropertyType, defaultCheckboxProperties, defaultDropDownProperties, defaultStringProperties } from "./definitions";
import { IEntityItem } from "./entitiesDefinitions";
import { IAddressInfo, IContactInfo } from "./issuerDefinitions";

interface IClientOptiEarningsProperty {
    isParticipatingInBreakageAndEarnings?: boolean;
    reportFiscalYearStartMonth?: string;
    accountingMethod?: string;
    trueUpMonth?: number | null;
    trueUpMethod?: string;
    earningsReserve?: number | null;
    recaptureReserve?: number | null;
    isApplyPSA?: boolean;
    optiEarningNotes?: string;
    taxDeferralYears?: boolean;
    reserveReporting?: string;
    reporting?: string;
    includeRecapturesInEarnings?: boolean;
    includeRecapturesInForecastedEarnings?: boolean;

};
interface IClientOptiforProperty extends IClientOptiEarningsProperty {
    isParticipatingInBreakageForecasting?: boolean;
    activationsStartDate?: Date;
    activationsEndDate?: Date;
    isLessThanThreeYearsData?: boolean;
    isUsingIndustryCurvesLibrary?: boolean;
    isStartingProgramOnRemoteEarnings?: boolean;
    isProcessingExcludedCards?: boolean;
    isSkippingForecastingCalculation?: boolean;
    isBrickAndMortarRedemptions?: boolean;
    isOnlineRedemptions?: boolean;
    isWalletRedemptions?: boolean;
    isPhysicalMedium?: boolean;
    isVirtualEGiftMedium?: boolean;
    isWalletAccountPhysicalMedium?: boolean;
    isLoadedIntoAccountEGiftMedium?: boolean;
    isLoadedIntoAccountMedium?: boolean;
    monthFundsExpire?: string;
    monthPlasticExpires?: string;
    reloadableProgram?: boolean;
    industryType?: string;
    optiForExpirationNotes?: string;
    isFeeProgramApplicable?: boolean;
    isUpfrontOrActivationFee?: boolean;
    activationFeeAmount?: number;
    isIncludedInSoldAmountToCardCompliant?: boolean;
    isRecurringFees?: boolean;
    recurringFeeAmount?: number;
    frequency?: string;
    recurringFeeStartMonth?: string;
    feesStartBySoldMonthOrInactivity?: boolean;
    clientResponseAddressID?: number;
    clientReturnAddressID?: number;
    responsePhoneNumber?: string;
    doesEntityHaveNewIssuance?: boolean;
    optiForRedemptionOrProgramDetailsForForecastingNotes?: string;
    factorType?: string;
    feeStartMonth?: string;
    feeStopMonth?: string;
    otherIndustryType?: string;

};

interface IClientProperty extends IClientOptiforProperty {
    currencyCodeAlpha: string;
    doesCardCompliantDeliverNotices: boolean | null;
    doesCardCompliantReceiveResponses: boolean;
    doesCardCompliantProcessRTSMail: boolean;
    doesCardCompliantSignForms: boolean | null;
    doesCardCompliantSendForms: boolean | null;
    doesCardCompliantRemitForms: boolean | null;
    deliverPropertyListToClient: boolean;
    deliverEscheatFilingFilesToClient: boolean;
    runTaxReport: boolean;
    calendarEndDateToAdjust: Date;
    calendarStartDate: Date;
    taxPeriodEndDate: Date;
    approvedExceptionVariance: number;
    thirdPartyDistributorDefaultStateSold: string;
    thirdPriorityInternetDefaultStateSold: string;
    defaultStateSold: string;
    fiscalCalendarType: string;
    calendarNumberOfDaysToAdjust: number | null;
    doesCardCompliantServeAsFilingContact: boolean;
    doesCardCompliantReceiveResponsesResponseType: string;
    doesClientWantToEMailNotice: boolean;

};

interface IClientContractProperty extends IClientProperty {
    contractNotes: string;
    unclaimedPropertyServicesTrackingReports: boolean | null;
    unclaimedPropertyDueDiligenceNoticesService: boolean | null;
    stateUnclaimedPropertyReportFilingService: boolean | null;
    escheatRemittanceServices: boolean | null;
    collateralUnclaimedPropertyFilingsAndNoticeServices: boolean | null;
    unclaimedPropertyStateInquiryServices: boolean | null;
    postEscheatCardholderServices: boolean | null;
    earningsRecognitionServices: boolean | null;
    taxNumbersServices: boolean | null;
    intercompanyPaymentServices: boolean | null;
    breakageForecastingServices: boolean | null;
    unclaimedPropertyStateAuditSupportService: boolean | null;
    financialStatementAuditSupportServices: boolean | null;
    consumerProtectionTermsAndConditionsServices: boolean | null;
    consumerProtectionCashOffServices: boolean | null;
    issuerFormalitiesServices: boolean | null;
    documentStorageService: boolean | null;
    technologySupportService: boolean | null;
    postTerminationServices: boolean | null;
    antiMoneyLaunderingClosedLoopExemptionServices: boolean | null;
};

interface IClientItem extends IClientContractProperty {
    id: number;
    clientCode: string;
    clientName: string;
    description: string;
    billingContactFirstName?: string;
    billingContactLastName?: string;
    billingContactPhone?: string;
    billingContactEmail?: string;
    billingAddress?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: string;
    billingZip?: string;
    companyType?: string;
    corporateFilingAddress?: string;
    corporateFilingAddress1?: string;
    corporateFilingAddress2?: string;
    corporateFilingCity?: string;
    corporateFilingContact1Address1?: string;
    corporateFilingContact1Address2?: string;
    corporateFilingContact1Address3?: string;
    corporateFilingContact1City?: string;
    corporateFilingContact1Country?: string;
    corporateFilingContact1Email?: string;
    corporateFilingContact1Fax?: string;
    corporateFilingContact1Name?: string;
    corporateFilingContact1Phone?: string;
    corporateFilingContact1State?: string;
    corporateFilingContact1Title?: string;
    corporateFilingContact1Zip?: string;
    corporateFilingContact2Address1?: string;
    corporateFilingContact2Address2?: string;
    corporateFilingContact2Address3?: string;
    corporateFilingContact2City?: string;
    corporateFilingContact2Country?: string;
    corporateFilingContact2Email?: string;
    corporateFilingContact2Fax?: string;
    corporateFilingContact2Name?: string;
    corporateFilingContact2Phone?: string;
    corporateFilingContact2State?: string;
    corporateFilingContact2Zip?: string;
    corporateFilingCountry?: string;
    corporateFilingCounty?: string;
    corporateFilingINCDate?: string;
    corporateFilingName?: string;
    corporateFilingNAICSCode?: string;
    corporateFilingNumberOfEmployees?: number | null;
    corporateFilingSICCode?: string;
    corporateFilingState?: string;
    corporateFilingTaxId?: string;
    corporateFilingTaxIdExt?: string;
    corporateFilingZip?: string;
    corporateFilingContact2Title?: string;
    corporateFilingFax?: string;
    corporateFilingINCState?: string;
    corporateFilingLastYearFiscalRevenue?: number | null;
    holderCorporateName?: string;
    monthsToEarnings?: string;
    principlePlaceOfBusinessAddress?: string;
    principlePlaceOfBusinessAddress2?: string;
    principlePlaceOfBusinessCity?: string;
    principlePlaceOfBusinessState?: string;
    principlePlaceOfBusinessZip?: string;
    technicalContactEmail?: string;
    technicalContactFirstName?: string;
    technicalContactLastName?: string;
    technicalContactPhone?: string;
    sic?: string;
    optiForDataRange?: string;
    status?: number;
    userId?: string;
    frequencyOfCardData?: string;
    margin?: string;
    optiForProcessExcludedCards?: string;
    escheatProduct?: string;
    applyProvisionalSeasonalAdjustment?: boolean;
    primarilySellingTangiblePersonalPropertyAtRetail?: boolean;
    isQualifiedRestaurantRule?: boolean;
    inboundFileLocation?: string;
    inboundFileID?: string;
    inboundPushPull?: string;
    processorID?: string;
    entityId: number | null;
    issuerType: string;
    clientDisplayName: string;
    corporateFilingTotalAssets?: number | null;
    contractSignedDate: Date;
    statusName: string;
    noticesAndFilingNotes: string;
    reportingPeriodStartMonthly: string;
    reportingPeriod: number | null;

    currentPortfolioDate?: string;
    currentPortfolioCondition?: string;
    historicalPortfolioDate?: string;
    historicalPortfolioCondition?: string;
    excludedCardsFrom?: string;
    excludedCardsTo?: string;
    transactionLocation?: string;
    personalInfoFragmentFilter?: string;
    permittedPersonalInfoFragments?: string;
    corporateFilingINCCountry?: string;
    isTestOrganization?: boolean | null;

    addresses: IAddressInfo[] | null;
    contacts: IContactInfo[] | null;
    customReportingPeriods?: ICustomReportingPeriod[];
    programs: IProgramItem[] | null;
    entity: IEntityItem | null;
};

interface ICustomReportingPeriod {
    id: number;
    index: number;
    startDate: string;
};

interface IClientProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    processorCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
};

interface IOrganizationItem {
    id: number;
    code: string;
    name: string;
    description: string;
    type: number;
    entityName: string;
    isDraft: boolean;
};

type OrganizationState = FormState & {
    id?: number;
    type?: number;
};

interface IAddOrganizationItem {
    code: string;
    name: string;
    description: string;
    type: number;
}

interface IIssuerItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    issuerType: string;
    entity: IEntityItem;
    isStaging?: boolean;
};


interface IProcessorItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    processorDisplayName: string;
    entity: IEntityItem;
};

interface IProgramProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    programCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
    createdDate: Date
};

interface IProgramItem {
    id: number;
    clientCode: string;
    programCode: string;
    processorCode: string;
    escheatProduct: string;
    frequencyOfCardData: string;
    name: string;
    programDescription: string;
    status: string;
    runTaxReport: boolean;
    leadIssuerId: number | null;
    otherIssuerId: number | null;
    leadIssuerType: string;
    otherIssuerType: string;
    cardCompliantClientNumber: string;
    programDisplayName: string;
    processorName: string;
    leadIssuerName: string;
    otherIssuerName: string;
    contractSignedDate: Date;

    doesCardCompliantDeliverNotices: boolean;
    doesCardCompliantReceiveResponses: boolean;
    currencyCodeAlpha: string;

    leadIssuerStagingId: number | null;
    otherIssuerStagingId: number | null;
    leadIssuerStagingName: string;
    otherIssuerStagingName: string;
    leadIssuerStagingType: string;
    otherIssuerStagingType: string;

    //Other UI fields
    combinedLeadIssuerId: string;
    combinedOtherIssuerId: string;
};

interface INewProgramItem {
    clientCode: string;
    programCode: string;
    processorCode: string;
    leadIssuerId: number;
    otherIssuerId: number | null;
    leadIssuerStagingId: number | null;
    otherIssuerStagingId: number | null;
    status: string;
    currencyCodeAlpha: string;
    programDescription: string;
    name: string;

    //Other UI fields
    combinedLeadIssuerId: string;
    combinedOtherIssuerId: string;
}

interface ICurrencyItem {
    currencyCodeAlpha: string;
    currencySymbol: string;
    currencyDisplayName: string;
};

const organizationTypes = [
    { name: 'Client', value: 0 },
    { name: 'Issuer', value: 1 },
    { name: 'Processor', value: 2 },
]

const LIST_PARTIES = [
    { name: 'Card Compliant', value: true },
    { name: 'Client', value: false },
]

const organizationTypeEnum = ["Client", "Issuer", "Processor"];

const clientStringProperties: IPropertyType = {
    ...defaultStringProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientCheckboxProperties: IPropertyType = {
    ...defaultCheckboxProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

enum ProfileAttributeNameEnum {
    DoesCardCompliantDeliverNotices = "DoesCardCompliantDeliverNotices",
    DoesCardCompliantReceiveResponses = "DoesCardCompliantReceiveResponses",
    DoesClientWantToEMailNotice = "DoesClientWantToEMailNotice",
    DoesCardCompliantProcessRTSMail = "DoesCardCompliantProcessRTSMail",
    CurrencyCodeAlpha = "CurrencyCodeAlpha",
    DefaultStateSold = "DefaultStateSold",
    ThirdPriorityInternetDefaultStateSold = "ThirdPriorityInternetDefaultStateSold",
    ThirdPartyDistributorDefaultStateSold = "ThirdPartyDistributorDefaultStateSold",
    ApprovedExceptionVariance = "ApprovedExceptionVariance",
    DoesCardCompliantSignForms = "DoesCardCompliantSignForms",
    DoesCardCompliantSendForms = "DoesCardCompliantSendForms",
    DoesCardCompliantRemitForms = "DoesCardCompliantRemitForms",
    DeliverPropertyListToClient = "DeliverPropertyListToClient",
    DeliverEscheatFilingFilesToClient = "DeliverEscheatFilingFilesToClient",
    RunTaxReport = "RunTaxReport",
    CalendarEndDateToAdjust = "CalendarEndDateToAdjust",
    CalendarNumberOfDaysToAdjust = "CalendarNumberOfDaysToAdjust",
    CalendarStartDate = "CalendarStartDate",
    FiscalYearStartDate = "FiscalYearStartDate",
    TaxPeriodEndDate = "TaxPeriodEndDate",
    CardCompliantReturnAddress = "CardCompliantReturnAddress",
    ClientReturnAddress = "ClientReturnAddress",
    CardCompliantResponseType = "CardCompliantResponseType",
    FiscalCalendarType = "FiscalCalendarType",
}

type NewDraftClientFormState = FormState & {
    id?: number;
};

const clientStagingStatuses = [
    { name: 'New', value: 0 },
    { name: 'Sales', value: 1 },
    { name: 'Contracting', value: 2 },
    { name: 'Implementing', value: 3 },
]

const programStatuses = [
    { name: 'Sales', value: 'Sales' },
    { name: 'Contracting', value: 'Contracting' },
    { name: 'Implementing', value: 'Implementing' },
]

enum ClientStagingStatusEnum {
    New = 0,
    Sales = 1,
    Contracting = 2,
    Implementing = 3,
    PublishApproval = 4
}

type NewDraftFormState = FormState & {
    id?: number;
};

const formFieldsProperties = {
    xsInput: 3,
    xsLabel: 5,
}

const radioFormFieldsProperties = {
    xsInput: 2,
    xsLabel: 6,
}

const dateFormFieldsProperties = {
    xsInput: 1.7,
    xsLabel: 6.3,
}

const optiRadioFormFieldsProperties = {
    xsInput: 2,
    xsLabel: 5,
}

const optiFormFieldsProperties = {
    xsInput: 3,
    xsLabel: 4,
}

const optiDateFormFieldsProperties = {
    xsInput: 1.7,
    xsLabel: 5.3,
}

export const CONDITION_OPTIONS = [
    { value: '<', name: '<' },
    { value: '>', name: '>' },
    { value: '<=', name: '<=' },
    { value: '>=', name: '>=' },
    { value: '=', name: '=' },
];

export const TRANSACTION_LOCATION_OPTIONS = [
    { value: 'None', name: 'None' },
    { value: 'No', name: 'No' },
    { value: 'Yes', name: 'Yes' },
];

export const PERSONAL_INFO_FRAGMENT_FILTER_OPTIONS = [
    { value: 'None', name: 'None' },
    { value: 'No', name: 'No' },
    { value: 'Yes', name: 'Yes' },
    { value: 'Property Owner Information', name: 'Property Owner Information' },
];

export const PERMITTED_PERSONAL_INFO_FRAGMENTS_OPTIONS = [
    { value: 'All', name: 'All' },
    { value: 'None', name: 'None' },
];

export const LIST_FACTOR_TYPE =
    [
        { value: "Cloud Factor", name: "Cloud Factor" },
        { value: "Balance Curve Method", name: "Balance Curve Method" },
        { value: "Fee Curve Method", name: "Fee Curve Method" },
        { value: "Legacy Factor", name: "Legacy Factor" },
    ]

export const LIST_ACCOUNTING_METHOD =
    [
        { value: "Remote", name: "Remote" },
        { value: "Accelerated", name: "Accelerated" },
        { value: "Proportionate", name: "Proportionate" },
    ]

export const LIST_TRUE_UP_METHOD =
    [
        { value: "True to Breakage", name: "True to Breakage" },
        { value: "True to Balance", name: "True to Balance" },
        { value: "True to 0 Balance", name: "True to 0 Balance" },
        { value: "Balance", name: "Balance" },
    ]

export const LIST_FREQUENCY =
    [
        { value: "Monthly", name: "Monthly" },
        { value: "Annually", name: "Annually" },
    ]

export const LIST_RESERVE_REPORTING =
    [
        { name: "Recapture Reserve", value: "RecaptureReserve" },
        { name: "Residual Redemption Reserve", value: "ResidualRedemptionReserve" },
        { name: "No Reserve Report", value: "NoReserveReport" },
    ]

export const LIST_DE_RECOGNITION_REPORTING_TYPE =
    [
        { name: "Basic", value: "Basic" },
        { name: "Expiration", value: "Expiration" },
        { name: "Fee", value: "Fee" },
        { name: "Fee/Expiration", value: "Fee/Expiration" },
        { name: "Legacy", value: "Legacy" },
        { name: "Reload", value: "Reload" },
    ]

export const LIST_INDUSTRY_TYPE =
    [
        { value: " ", name: "Select Industry Type" },
        { value: "Dining", name: "Dining" },
        { value: "Open Loop", name: "Open Loop" },
        { value: "Entertainment", name: "Entertainment" },
        { value: "Retail", name: "Retail" },
        { value: "Travel", name: "Travel" },
        { value: "Gas", name: "Gas" },
        { value: "Other", name: "Other" }
    ]

export {
    organizationTypeEnum,
    organizationTypes,
    clientStringProperties,
    clientDropDownProperties,
    clientCheckboxProperties,
    ProfileAttributeNameEnum,
    LIST_PARTIES,
    clientStagingStatuses,
    ClientStagingStatusEnum,
    programStatuses,
    formFieldsProperties,
    radioFormFieldsProperties,
    dateFormFieldsProperties,
    optiRadioFormFieldsProperties,
    optiFormFieldsProperties,
    optiDateFormFieldsProperties,
}
export type { OrganizationState, }
export type {
    IClientItem,
    IOrganizationItem,
    IAddOrganizationItem,
    IIssuerItem,
    IProcessorItem,
    IProgramItem,
    IProgramProfileAttribute,
    ICurrencyItem,
    NewDraftFormState,
    ICustomReportingPeriod,
    INewProgramItem
}