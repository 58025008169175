import { colors, defaultStyles } from "@components/ThemeRegistry/theme";
import { PopoverOrigin } from "@mui/material";

export const fullWidthProps = {
	width: "100%",
}

export const fullHeightProps = {
	height: "100%",
}

export const fullWidthHeightProps = {
	width: "100%",
	height: "100%",
}

export const paddingBottom2Props = {
	pb: 2
}

export const paddingTop3Props = {
	pt: 3
}

export const widthFitContentProps = {
	width: "fit-content",
}

export const fontWeight700Props = {
	fontWeight: "700",
}

export const justifyContentProps = {
	justifyContent: "center",
}

export const justifyBetweenSpaceProps = {
	justifyContent: "space-between",
}

export const justifyContentEndProps = {
	justifyContent: "end",
}

export const textAlignCenterProps = {
	textAlign: "center"
}

export const cursorPointerProps = {
	cursor: "pointer",
}

export const paddingY1Props = {
	py: 1,
}

export const paddingY2Props = {
	py: 2,
}

export const paddingX1Props = {
	px: 1,
}

export const paddingTop3pxProps = {
	pt: "3px",
}

export const transformOriginProps: PopoverOrigin = {
	vertical: "top",
	horizontal: "right",
}

export const anchorOriginProps: PopoverOrigin = {
	vertical: "bottom",
	horizontal: "right",
}

export const hoverFocusWhiteProps = {
	"&:hover": {
		bgcolor: "white",
	},
	"&:focus-visible": {
		bgcolor: "white",
	},
}

export const hoverFocusTransparentProps = {
	"&:hover": {
		bgcolor: "transparent",
	},
	"&:focus-visible": {
		bgcolor: "transparent",
	},
}

export const gridCellTableCenterProps = {
	textAlign: "center",
	py: 1
}

export const gridCellTableLeftProps = { py: 1, px: 1, textAlign: "left", justifyContent: "left" }

export const boxShadowStyle = {
	...fullWidthProps,
	borderRadius: 2,
	boxShadow:
		"5px 5px 5px 5px rgba(151, 153, 155, 0.2), -5px -5px 5px 5px rgba(151, 153, 155, 0.2);",
};

export const formLabelStyle = {
	...fontWeight700Props,
	color: "black",
	fontSize: "18px",
};

export const selectMenuPropStyle = (zIndex: number) => {
	return {
		style: {
			zIndex: zIndex + 2,
			width: "350px",
		},
		PaperProps: {
			sx: {
				maxHeight: "30vh",
				"@media screen and (max-width: 1366px) and (max-height: 768px)":
				{
					maxHeight: "27vh",
				},
			},
		},
		disableScrollLock: true,
	};
};

export const flexCenterProps = {
	display: "flex",
	alignItems: "center"
}

export const flexCenterNonePaddingLeftProps = {
	...flexCenterProps,
	pl: 0
}

export const flexRowProps = {
	...flexCenterProps,
	flexDirection: "row",
}

export const flexRowReverseProps = {
	...flexCenterProps,
	flexDirection: "row-reverse",
}

export const flexEndProps = {
	display: "flex",
	alignItems: "end"
}

export const flexStartProps = {
	display: "flex",
	alignItems: "start"
}

export const flexColumnProps = {
	display: 'flex',
	flexDirection: "column",
}

export const flexColumnReverseProps = {
	display: 'flex',
	flexDirection: "column-reverse",
}

export const menuItemStyle = {
	whiteSpace: "normal",
	wordBreak: "break-word",
	minWidth: "300px",
	"@media screen and (max-width: 1366px) and (max-height: 768px)": {
		fontSize: "14px",
	},
};

export const horizontalFormControlGridLeftStyle = {
	...flexCenterProps,
	justifyContent: "end",
	paddingRight: "16px",
};

export const horizontalFormControlGridRightStyle = {
	...flexCenterProps,
	paddingLeft: "16px",
};

export const titleUnderlineStyle = {
	...fontWeight700Props,
	color: "black",
	fontSize: "16px",
	textDecoration: "underline",
};

export const squareNavyButtonInSmallScreen = {
	"@media screen and (max-width: 1366px) and (max-height: 768px)": {
		height: 36,
	},
};

export const menuItemDisableWithoutOpacityStyle = {
	...flexCenterProps,
	...justifyContentProps,
	...fullWidthProps,
	height: "120px",
	"&.Mui-disabled": {
		opacity: 1,
	},
};

export const tableCellProps = {
	display: "inline",
	color: "text.secondary",
	wordWrap: "break-word",
}

export const inlineActionButtonProps = {
	fontSize: "inherit",
	textDecoration: "underline",
	textTransform: "none",
}

export const w200NowrapEllipsisProps = {
	width: "200px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
}

export const inlineAGGridActionButtonProps = {
	...inlineActionButtonProps,
	...hoverFocusTransparentProps,
}

export const formControlProps = {
	marginBottom: 2,
	"@media screen and (max-width: 1366px) and (max-height: 768px)":
	{
		marginBottom: 1,
	},
}

export const formControlMarginTopProps = {
	marginTop: 2,
	"@media screen and (max-width: 1366px) and (max-height: 768px)":
	{
		marginTop: 1,
	},
}

export const w100ButtonProps = {
	width: 100,
}

export const modalStyle = {
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: defaultStyles.modalWidth,
	height: "fit-content",
	backgroundColor: "background.paper",
	border: "none",
	borderRadius: defaultStyles.modalBorderRadius,
	boxShadow: 4,
	"&:focus-visible": {
		outline: "none",
	},
	"& .MuiBackdrop-root": {
		backgroundColor: "background.paper",
		borderRadius: "20px",
	},
}

export const flexGapProps = {
	display: "flex",
	gap: 1,
}

export const verticalFormControlGridStyle = {
	...flexCenterProps,
	justifyContent: "start",
	pt: "2px",
	px: "8px",
	alignContent: 'flex-start',
};

export const verticalFormControlPyGridStyle = {
	...flexCenterProps,
	justifyContent: "start",
	py: "5px",
};

export const styleFormGridOrgProps = {
	py: 2,
	px: 1,
}

export const checkBoxOrgProps = {
	"&:hover": {
		backgroundColor: 'transparent'
	}
}

export const styledButtonProps = {
	width: "200px",
	mx: 1,
	mb: 1,
	p: 1,
	"&.active": {
		backgroundColor: colors.navy,
		color: colors.white,
	}
}

export const styledActionButtonProps = {
	...styledButtonProps,
	fontWeight: "bold",
	width: "150px",
	p: "4px"
}

export const styledBoldTypographyProps = {
	fontSize: "14px",
	...fontWeight700Props,
}

export const styledNotificationNumberProps = {
	...fontWeight700Props,
	background: colors.red,
	color: colors.white,
	fontSize: "10px",
	ml: 1,
	p: "3px 6px",
	borderRadius: "4px",
}

export const requiredLabelStyle = {
	wordWrap: "break-word",
	width: "100%",
	display: "flex",
	gap: "5px",
	flexDirection: "row",
	fontWeight: 400,
	overflowWrap: "anywhere",
	textWrap: 'wrap',
}

export const labelStyle = {
	...requiredLabelStyle,
	textAlign: "left",
	fontWeight: 600,
}

export const overflowYStyle = {
	overflow: "hidden",
	overflowY: "auto",
}

export const overflowYOfListStyle = {
	maxHeight: "calc(95vh - 52vh)",
	overflow: "hidden",
	overflowY: "auto",
}

export const flexRowGap1Props = {
	...flexRowProps,
	gap: 1,
}

export const borderBottomProps = {
	borderBottom: "1px solid",
	borderBottomColor: colors.black15Per,
}

export const borderTopProps = {
	borderTop: "1px solid",
	borderTopColor: colors.black15Per,
}

export const pageTitleProps = {
	fontSize: "26px",
	fontWeight: 400,
	fontFamily: "Franklin Gothic Medium",
	lineHeight: "30px",
}

export const titleContextProps = {
	fontSize: "22px",
	fontWeight: 400,
	fontFamily: "Franklin Gothic Medium",
	lineHeight: "25px",
	overflowWrap: "break-word",
}

export const subTitleContextProps = {
	fontSize: "14px",
	fontWeight: 300,
	fontFamily: "Franklin Gothic Regular",
	lineHeight: "20px",
	overflowWrap: "break-word",
}

export const messageProps = {
	fontSize: "20px",
	fontWeight: 400,
	fontFamily: "Franklin Gothic Book",
	lineHeight: "23px",
	overflowWrap: "break-word",
}

export const typographyProps = {
	color: colors.black60Per,
	fontWeight: 400,
	overflowWrap: "break-word",
}

export const contentProps = {
	fontSize: "20px",
	fontWeight: 500,
	lineHeight: "25px",
	overflowWrap: "break-word",
}

export const boldLabelProps = {
	fontSize: "16px",
	fontWeight: 500,
	lineHeight: "22px",
	overflowWrap: "break-word",
}

export const spanProps = {
	fontSize: "16px",
	fontWeight: 400,
	lineHeight: "20px",
	overflowWrap: "break-word",
}

export const tabHeaderProps = {
	...titleContextProps,
	background: colors.slate10Per,
	height: "50px !important",
	minHeight: "50px !important",
	borderBottom: `5px solid ${colors.slate}`,
	opacity: 0.5,
}

export const accordionProps = {
	m: "10px 0 !important",
	border: "none",
	boxShadow: "none",
	borderRadius: 0,
	"& .Mui-expanded": {
		opacity: 1
	}
}

export const flexSelfStartProps = {
	alignSelf: "start"
}

export const borderTopFormControl = {
	...borderTopProps,
	...paddingX1Props,
}
export const borderBottomFormControl = {
	...borderBottomProps,
	...paddingX1Props,
}

export const padding1Props = {
	p: 1
}

export const padding2Props = {
	p: 2
}

export const padding3Props = {
	p: 3
}

export const asteriskRed = {
	"& .MuiFormLabel-asterisk": {
		color: colors.red
	}
}

export const noneOutlineFocus = {
	"&:focus-visible": {
		outline: "none",
	},
}

export const blurModelProps = {
	backgroundColor: colors.white,
	opacity: 0.9,
}

export const customDisabledOutlineInput = {
	"& .MuiOutlinedInput-root.Mui-disabled": {
		"& fieldset": {
			borderColor: "#606060",
		},
	},
	"& .MuiInputLabel-outlined.Mui-disabled": {
		color: "#808080",
	},
}

export const selectStyle = {
	p: "2px 3px 0px 3px",
	"@media screen and (max-width: 1366px)":
	{
		fontSize: "14px",
	},
	"& .MuiSelect-select":
	{
		p: "7px 6px 7px 6px",
	},
	"& .MuiSelect-select .notranslate":
	{
		...flexCenterProps,
	},
};
export const selectLabelStyle = {
	'&[data-shrink="false"]': {
		transform: 'translate(14px, 10px) scale(0.8)',
	},
}

export const flexStartGrid = {
	...paddingX1Props,
	alignContent: 'flex-start'
}

export const defaultGrid = {
	...paddingX1Props,
	alignContent: 'center'
}